import s from './Error404.module.css';

const Error404 = () => {
  return (
    <svg
      className="w-[300px] sm:w-[395px]"
      height="173"
      viewBox="0 0 404 173"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2208_50301)">
        <path
          d="M174.047 39.4134C170.447 39.4134 162.214 34.0801 158.547 31.4134C160.147 27.8134 167.214 28.2468 170.547 28.9134L181.547 35.9134C180.547 37.0801 177.647 39.4134 174.047 39.4134Z"
          fill="#373737"
        />
        <path
          d="M155.544 15.9135L170.044 28.9135C144.044 29.9135 142.044 59.4135 142.544 64.4135C142.944 68.4135 140.711 69.4135 139.544 69.4135C137.878 69.4135 132.944 68.6135 126.544 65.4135C120.144 62.2135 121.878 52.7469 123.544 48.4135L139.544 20.9135C144.344 14.1135 152.211 14.7469 155.544 15.9135Z"
          fill="#F7E634"
        />
        <path d="M189 33.1797L202.5 20.1797L197 32.1797L189 33.1797Z" fill="#373737" />
        <path d="M217.5 33.6797L204 20.6797L209.5 32.6797L217.5 33.6797Z" fill="#373737" />
        <path
          d="M267.935 124.774V124.893C267.935 124.893 259.138 126.473 261.032 142.826C255.745 141.839 252.629 139.232 252.629 139.232C237.559 123.668 264.109 124.182 264.109 124.182L267.935 124.774Z"
          fill="#47A3F4"
        />
        <path
          d="M285.487 139.429C285.487 139.429 280.714 141.049 268.682 142.905C266.236 143.3 264.066 143.261 262.094 142.984C265.329 142.629 275.191 140.377 277.676 126.275L282.765 127.065C282.765 127.065 289.432 128.092 290.813 131.687C292.233 135.321 285.487 139.429 285.487 139.429Z"
          fill="#F0E54D"
        />
        <path
          d="M277.68 126.275C275.195 140.377 265.333 142.629 262.098 142.984C261.743 142.945 261.388 142.866 261.033 142.826C259.139 126.473 267.936 124.893 267.936 124.893V124.774L277.68 126.275Z"
          fill="#373737"
        />
        <path
          d="M148.08 129.774V129.893C148.08 129.893 156.878 131.473 154.984 147.826C160.27 146.839 163.387 144.232 163.387 144.232C178.456 128.668 151.907 129.182 151.907 129.182L148.08 129.774Z"
          fill="#47A3F4"
        />
        <path
          d="M130.528 144.429C130.528 144.429 135.302 146.049 147.334 147.905C149.78 148.3 151.949 148.261 153.922 147.984C150.687 147.629 140.825 145.377 138.339 131.275L133.25 132.065C133.25 132.065 126.583 133.092 125.203 136.687C123.782 140.321 130.528 144.429 130.528 144.429Z"
          fill="#F0E54D"
        />
        <path
          d="M138.335 131.275C140.821 145.377 150.683 147.629 153.918 147.984C154.273 147.945 154.628 147.866 154.983 147.826C156.877 131.473 148.079 129.893 148.079 129.893V129.774L138.335 131.275Z"
          fill="#373737"
        />
        <ellipse cx="203.5" cy="93.6797" rx="59.5" ry="62.5" fill="#3C8CD0" />
        <ellipse cx="203.5" cy="93.6797" rx="50.5" ry="62.5" fill="#47A3F4" />
        <ellipse cx="168" cy="78.6797" rx="28" ry="31.5" fill="#47A3F4" />
        <ellipse cx="171.5" cy="78.6797" rx="26.5" ry="31.5" fill="#3C8CD0" />
        <ellipse cx="169.5" cy="78.6797" rx="26.5" ry="29.5" fill="white" />
        <ellipse cx="174.5" cy="79.6797" rx="21.5" ry="24.5" fill="#E85DBA" />
        <ellipse cx="178" cy="78.1797" rx="18" ry="19" fill="#373737" />
        <ellipse cx="189.5" cy="68.1797" rx="3.5" ry="4" fill="white" />
        <ellipse rx="28.5" ry="31.5" transform="matrix(-1 0 0 1 239.5 76.6797)" fill="#47A3F4" />
        <ellipse rx="27" ry="29.5" transform="matrix(-1 0 0 1 238 76.6797)" fill="white" />
        <ellipse rx="21.5" ry="24.5" transform="matrix(-1 0 0 1 232.5 77.6797)" fill="#E85DBA" />
        <ellipse rx="18" ry="19" transform="matrix(-1 0 0 1 229 76.1797)" fill="#373737" />
        <ellipse rx="3.5" ry="4" transform="matrix(-1 0 0 1 238.5 64.1797)" fill="white" />
        <path
          d="M219.5 118.18H188C186.4 118.18 186 119.18 186 119.68V124.68C186 127.68 192 130.68 204 130.68C214.032 130.68 221.5 127.18 221.5 124.68V119.68C221.5 118.68 220.5 118.18 219.5 118.18Z"
          fill="#373737"
        />
        <rect x="197" y="118.18" width="14" height="3" fill="white" />
        <path
          d="M203.5 128.18C197.9 128.18 196.5 129.513 196.5 130.18C197.014 130.18 199.753 130.68 203.5 130.68C205.59 130.68 208 130.68 210.5 130.18C210.5 129.513 209.1 128.18 203.5 128.18Z"
          fill="#E3663B"
        />
        <circle cx="205.5" cy="93.6797" r="1.5" fill="#3D8ACF" />
        <circle cx="199" cy="99.1797" r="1" fill="#3D8ACF" />
        <circle cx="202" cy="103.18" r="1" fill="#3D8ACF" />
        <circle cx="208" cy="106.18" r="1" fill="#3D8ACF" />
        <circle cx="206" cy="98.1797" r="1" fill="#3D8ACF" />
        <circle cx="209" cy="100.18" r="1" fill="#3D8ACF" />
      </g>
      <g filter="url(#filter1_d_2208_50301)">
        <path
          d="M113.2 125.573L115.866 153.183L84.4547 156.215L81.7892 128.606L15.166 135.038L12.4425 106.828L61.7764 15.4352L101.99 11.5529L110.373 98.3832L129.98 96.4903L132.607 123.7L113.2 125.573ZM74.3018 48.9587L73.7016 49.0167L45.1505 104.68L79.3624 101.377L74.3018 48.9587Z"
          className={s.responsivePathFill}
        />
      </g>
      <g filter="url(#filter2_d_2208_50301)">
        <path
          d="M369.643 126.333L367.031 153.947L335.615 150.977L338.226 123.362L271.59 117.061L274.258 88.8461L340.006 8.4493L380.228 12.2525L372.016 99.0991L391.626 100.953L389.053 128.168L369.643 126.333ZM345.956 43.7382L345.356 43.6814L306.781 92.9308L340.999 96.1663L345.956 43.7382Z"
          className={s.responsivePathFill}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2208_50301"
          x="110"
          y="7.17969"
          width="193.008"
          height="165"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.17 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2208_50301" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2208_50301"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_2208_50301"
          x="0.445312"
          y="3.55273"
          width="144.164"
          height="168.663"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.17 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2208_50301" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2208_50301"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_2208_50301"
          x="259.594"
          y="0.449219"
          width="144.031"
          height="169.498"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.17 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2208_50301" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2208_50301"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Error404;
